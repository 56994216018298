import React, { useState, useEffect,useContext } from 'react';
import Task from './task';
import { useSmarkState, useKeycloakState } from "../../Context/KeycloakContext";
import { ReactSortable } from "react-sortablejs";
import { trackPromise } from 'react-promise-tracker';
import { customFormDataPost } from '../../helper/api-helper/apiHerlpers';
import apiUrls from '../../constants/apiUrls';
import { toast } from 'react-toastify';
import QaTask from './qaTask';
import apis from '../../services/apis';
import $ from 'jquery';
import { format } from 'react-string-format';
import { customFetch } from '../../helper/api-helper/apiHerlpers';


const TaskBoard = (props) => {

    const contextDetails = useSmarkState();
    const storedToken = localStorage.getItem('keycloakToken');
    const initialState = {
        tasks: [],
        definedTasks: [],
        inProgressTasks: [],
        inQaTasks: [],
        completedTasks: [],
        userStories: [],
    };
    const [state, setState] = useState({ ...initialState });
    const keycloackValue = useKeycloakState();
    const [usersList, setUserList] = useState([]);
    useEffect(() => {
        
        getTaskLists(storedToken);
    }, [props]);

    const getTaskLists = async () => {
       
        setUserList(await apis.getUsers(storedToken));
        let tasks = contextDetails.taskList;
        if (tasks && tasks.length > 0) {
            setTaskByStatus(tasks);
        } else {
            setState({
                ...state,
                tasks: [],
                definedTasks: [],
                inProgressTasks: [],
                inQaTasks: [],
                completedTasks: [],
                userStories: [{ isEmptyRecord: true }],
            });
        }
    }

    const setTaskByStatus = async (tasks) => {
        let defined_tasks = tasks.filter(function (n) {
            return n.status.toLowerCase() === 'defined';
        });
        let inProgress_tasks = tasks.filter(function (n) {
            return n.status.toLowerCase() === 'in-progress';
        });
        let inQa_tasks = tasks.filter(function (n) {
            return n.status.toLowerCase() === 'in-qa';
        });
        let completed_tasks = tasks.filter(function (n) {
            return n.status.toLowerCase() === 'completed' || n.status.toLowerCase() === 'accepted';
        });
        const uniqueUserStories = tasks.map(item => { return { userStoryTitle: item.userStoryTitle, colorCode: item.colorCode, priority: item.priority, userStoryId: item.userStoryId } }).filter(function (value, index, self) {
            return self.map(x => x.userStoryTitle).indexOf(value.userStoryTitle) === index
        }).sort((a, b) => a.priority > b.priority ? 1 : a.priority < b.priority ? -1 : 0)


        setState({
            ...state,
            tasks,
            definedTasks: defined_tasks,
            inProgressTasks: inProgress_tasks,
            inQaTasks: inQa_tasks,
            completedTasks: completed_tasks,
            userStories: uniqueUserStories
        });
        props.setHeightOfAllColumn(false);
    }

    const updateTaskStatus = (newState, statusType) => {
        switch (statusType.toLowerCase()) {
            case "defined":
                setState({ ...state, definedTasks: newState });
                break;
            case "in-progress":
                setState({ ...state, inProgressTasks: newState });
                break;
            case "in-qa":
                setState({ ...state, inQaTasks: newState });
                break;
            case "completed":
                setState({ ...state, completedTasks: newState });
                break;
            case "accepted":
                setState({ ...state, completedTasks: newState });
                break;
            default:
                break;
        }

        props.setHeightOfAllColumn(true);
    }

    const onSortEnd = (order, sortable, evt) => {

        var taskId = $(order.item).data('taskid');
        var _tasks = state.tasks;
        var task = _tasks.filter(x => x.taskId === taskId);
        var newStatus = $(order.to).parent().data('status');

        if (taskId > 0 && task.length > 0) {

            if (task[0].status === newStatus)
                return;

            _tasks[_tasks.findIndex(x => x.taskId === taskId)].status = newStatus;
            setTaskByStatus(_tasks);

            var formdata = new FormData();
            formdata.append("applicationid", "252");
            formdata.append("siteid", "171");
            formdata.append("taskid", taskId);
            formdata.append("userid", contextDetails.userId);
            formdata.append("status", newStatus);
            formdata.append("swcmTicket", "ffb09f96-737b-4c2a-bd5e-2f3b40a585a5");

            trackPromise(
                customFormDataPost(apiUrls.updateTaskStatus, formdata,storedToken).then(async (response) => {
                    if (response) {

                        var task = await customFetch((format(apiUrls.getTaskById, taskId)),storedToken);
                        if (task.taskdetailsHB && task.taskdetailsHB.commentsList && task.taskdetailsHB.commentsList.length > 0) {
                            _tasks[_tasks.findIndex(x => x.taskId === taskId)].commentsList = task.taskdetailsHB.commentsList;
                            setTaskByStatus(_tasks);
                        }

                        toast.success(`Task status has been updated to ${task.taskdetailsHB.status}`, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }
                }).catch((error) => {
                    toast.error(error, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                })
            );
        }
    }

    const markTaskAcceptedCompleted = (taskId, newStatus) => {
        var _tasks = state.tasks;
        var task = _tasks.filter(x => x.taskId === taskId);

        if (taskId > 0 && task.length > 0) {

            if (task[0].status === newStatus)
                return;

            _tasks[_tasks.findIndex(x => x.taskId === taskId)].status = newStatus;
            setTaskByStatus(_tasks);

            var formdata = new FormData();
            formdata.append("applicationid", "252");
            formdata.append("siteid", "171");
            formdata.append("taskid", taskId);
            formdata.append("userid", contextDetails.userId);
            formdata.append("status", newStatus);
            formdata.append("swcmTicket", "ffb09f96-737b-4c2a-bd5e-2f3b40a585a5");

            trackPromise(
                customFormDataPost(apiUrls.updateTaskStatus, formdata,storedToken).then(async (response) => {
                    if (response) {

                        var task = await customFetch((format(apiUrls.getTaskById, taskId)),storedToken);
                        if (task.taskdetailsHB && task.taskdetailsHB.commentsList && task.taskdetailsHB.commentsList.length > 0) {
                            _tasks[_tasks.findIndex(x => x.taskId === taskId)].commentsList = task.taskdetailsHB.commentsList;
                            setTaskByStatus(_tasks);
                        }
                        toast.success(`Task status has been updated to ${task.taskdetailsHB.status}`, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }
                }).catch((error) => {
                    toast.error(error, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                })
            );
        }
    }

    const editStory = async (userStory) => {
        let StoryDetail = await customFetch((format(apiUrls.userStoryById, userStory)),storedToken);
        console.log(StoryDetail);
         props.editUserStory(true, StoryDetail, props.header_data, 'edit');
     }

    const phoneScreenShowTaskByStatus = function (e) {
        $(".st-cpse-defind").hide();
        $(".st-cpse-in-progress").hide();
        $(".st-cpse-in-qa").hide();
        $(".st-cpse-completed").hide();
        $(".st-cpse-accepted").hide();
        $(e).show();
    }

    return (
        <div>
            <section className="w-100 float-left show-ipad-view">
                <div className="wrapper">
                    <ul>
                        <li className="st-acc" onClick={() => phoneScreenShowTaskByStatus('.st-cpse-accepted')}>User stories</li>
                        <li className="st-def" onClick={() => phoneScreenShowTaskByStatus('.st-cpse-defind')}>Defined</li>
                        <li className="in-st-pro" onClick={() => phoneScreenShowTaskByStatus('.st-cpse-in-progress')}>In-Progress</li>
                        <li className="in-st-qa" onClick={() => phoneScreenShowTaskByStatus('.st-cpse-in-qa')}>In-QA</li>
                        <li className="cmp-st" onClick={() => phoneScreenShowTaskByStatus('.st-cpse-completed')}>Completed</li>
                    </ul>
                </div>
            </section>
            <section className="w-100 float-left mid-sec">
                {state.userStories.map((userStory, userStoryIndex) => (
                    <div className="w-100 float-left list-view storyListView" id="task_board" key={userStoryIndex}>
                        {userStoryIndex !== 0 ? <hr className='divider-hr' /> : ""}
                        <div className="w-20 float-left st-cpse-accepted">
                            {userStoryIndex === 0 ? <h3>User stories({state.userStories.filter(x => !x.isEmptyRecord).length})</h3> : ""}
                            <div id="task-list" className="full-list w-100 float-left taskDropArea" style={{ minHeight: userStory.isEmptyRecord ? "400px" : "200px" }} data-status="accepted">
                                {!userStory.isEmptyRecord ?
                                    <div className="float-left w-100">
                                        <div className="task-item w-100 float-left block shadow-sm" style={{ backgroundColor: userStory.colorCode }}>
                                            <div className="block-box w-100 float-left position-relative">
                                                <div className="heading-tp">
                                                    <span className="user-heading float-left" onClick={() => { editStory(userStory.userStoryId)} }>{userStory.userStoryTitle}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div> : ""}
                            </div>
                        </div>
                        <div className="w-20 float-left st-cpse-defind">
                            {userStoryIndex === 0 ? <h3>Defined({state.definedTasks.length})</h3> : ""}
                            <div id="task-list" className="full-list w-100 float-left taskDropArea" style={{ minHeight: userStory.isEmptyRecord ? "400px" : "200px" }} data-status="defined">
                                <ReactSortable className='w-100 '
                                    list={state.definedTasks}
                                    setList={(newState) => updateTaskStatus(newState, "defined")}
                                    animation={150}
                                    group="taskList"
                                    onChange={(order, sortable, evt) => { }}
                                    onEnd={onSortEnd}
                                >
                                    {state.definedTasks.filter(x => x.userStoryTitle === userStory.userStoryTitle).map((task) => <Task key={task.taskId} task={task} editTask={props.editTask} header_data={props.header_data} commentsVisible={props.commentsVisible} usersList={usersList}></Task>)}
                                </ReactSortable>
                            </div>
                        </div>
                        <div className="w-20 float-left st-cpse-in-progress">
                            {userStoryIndex === 0 ? <h3>In-Progress({state.inProgressTasks.length})</h3> : ""}
                            <div id="task-list" className="full-list w-100 float-left taskDropArea" style={{ minHeight: userStory.isEmptyRecord ? "400px" : "200px" }} data-status="in-progress">
                                <ReactSortable className='w-100 '
                                    list={state.inProgressTasks}
                                    setList={(newState) => updateTaskStatus(newState, "in-progress")}
                                    animation={150}
                                    group="taskList"
                                    onChange={(order, sortable, evt) => { }}
                                    onEnd={onSortEnd}
                                >
                                    {state.inProgressTasks.filter(x => x.userStoryTitle === userStory.userStoryTitle).map((task) => <Task key={task.taskId} task={task} editTask={props.editTask} header_data={props.header_data} commentsVisible={props.commentsVisible} usersList={usersList}></Task>)}
                                </ReactSortable>
                            </div>
                        </div>
                        <div className="w-20 float-left st-cpse-in-qa">
                            {userStoryIndex === 0 ? <h3>In-QA({state.inQaTasks.length})</h3> : ""}
                            <div id="task-list" className="full-list w-100 float-left taskDropArea" style={{ minHeight: userStory.isEmptyRecord ? "400px" : "200px" }} data-status="in-qa">
                                <ReactSortable className='w-100 '
                                    list={state.inQaTasks}
                                    setList={(newState) => updateTaskStatus(newState, "in-qa")}
                                    animation={150}
                                    group="taskList"
                                    onChange={(order, sortable, evt) => { }}
                                    onEnd={onSortEnd}
                                >
                                    {state.inQaTasks.filter(x => x.userStoryTitle === userStory.userStoryTitle).map((task) => <QaTask key={task.taskId} task={task} editTask={props.editTask} header_data={props.header_data} commentsVisible={props.commentsVisible} usersList={usersList}></QaTask>)}
                                </ReactSortable>
                            </div>
                        </div>
                        <div className="w-20 float-left st-cpse-completed">
                            {userStoryIndex === 0 ? <h3>Completed({state.completedTasks.length})</h3> : ""}
                            <div id="task-list" className="full-list w-100 float-left taskDropArea" style={{ minHeight: userStory.isEmptyRecord ? "400px" : "250px" }} data-status="completed">
                                <ReactSortable className='w-100 '
                                    list={state.completedTasks}
                                    setList={(newState) => updateTaskStatus(newState, "completed")}
                                    animation={150}
                                    group="taskList"
                                    onChange={(order, sortable, evt) => { }}
                                    onEnd={onSortEnd}
                                >
                                    {state.completedTasks.filter(x => x.userStoryTitle === userStory.userStoryTitle).map((task) => <Task key={task.taskId} task={task} editTask={props.editTask} header_data={props.header_data} commentsVisible={props.commentsVisible} usersList={usersList} markTaskAcceptedCompleted={markTaskAcceptedCompleted}></Task>)}
                                </ReactSortable>
                            </div>
                        </div>
                    </div>
                ))}
            </section>
        </div>
    )
}

export default TaskBoard;