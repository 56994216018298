import apiCommonParams from './apiCommonParams';
let baseURL = 'https://gw.api.smartwcm.com/rest/v3';
let adminBaseURL = 'https://gw.api.smartwcm.com/user/rest/v3';


const apiUrls = {
    saveProject: `${baseURL}/smark/save-project`,
    saveRelease: `${baseURL}/smark/save-release`,
    deleteProject: `${baseURL}/smark/delete-project`,
    deleteRelease: `${baseURL}/smark/delete-release`,
    project: baseURL + "/smark/projectslisthb/getallprojects/applicationid/252/siteid/171/userid/{0}?swcmTicket=ffb09f96-737b-4c2a-bd5e-2f3b40a585a5",
    release: baseURL + "/smark/projectrelaseshb/getallreleaseofaproject/applicationid/252/siteid/171/projectid/{0}/?swcmTicket=ffb09f96-737b-4c2a-bd5e-2f3b40a585a5",
    users: adminBaseURL + "/user/userlisthb/applicationid/252/siteid/171/module/0/count/0?swcmTicket=ffb09f96-737b-4c2a-bd5e-2f3b40a585a5",
    tasks: baseURL + "/smark/smark-user-story/releasetasklisthb/getalltaskcommentsofauserinadaterange/applicationid/252/siteid/171/projectid/{0}/releaseid/{1}/userid/{2}/userstoryid/{3}/startdate/{4}/enddate/{5}/entitytype/tm_task/?swcmTicket=ffb09f96-737b-4c2a-bd5e-2f3b40a585a5",

    report1: `${baseURL}/smark/report/reporttimeshb/getreportone/applicationid/252/siteid/171/projectId/{0}/releaseId/{1}?swcmTicket=ffb09f96-737b-4c2a-bd5e-2f3b40a585a5`,
    report2: `${baseURL}/smark/report/reporttimeshb/getreporttwo/applicationid/252/siteid/171/projectId/{0}/releaseId/{1}/startDate/{2}/endDate/{3}?swcmTicket=ffb09f96-737b-4c2a-bd5e-2f3b40a585a5`,
    report3: `${baseURL}/smark/report/reporttimeshb/getreportthree/applicationid/252/siteid/171/projectid/{0}/releaseid/{1}/startdate/{2}/enddate/{3}/status/Accepted/userid/{4}?swcmTicket=ffb09f96-737b-4c2a-bd5e-2f3b40a585a5`,
    report4: `${baseURL}/smark/report/reporttimeshb/getreportfour/applicationid/252/siteid/171/userid/{0}/startdate/{1}/enddate/{2}?swcmTicket=ffb09f96-737b-4c2a-bd5e-2f3b40a585a5`,
    stories: `${baseURL}/smark/smark-user-story/releasetasklisthb/getalluserstoryofarelease/applicationid/${apiCommonParams.applicationid}/siteid/${apiCommonParams.siteid}/releaseid/{0}/?swcmTicket=${apiCommonParams.swcmTicket}`,
    taskEditStories: `${baseURL}/smark/smark-user-story/userstorylisthb/getuserstorybysearch/applicationid/${apiCommonParams.applicationid}/siteid/${apiCommonParams.siteid}/projectid/{0}/keyword/{1}/?swcmTicket=${apiCommonParams.swcmTicket}`,
    saveStory: `${baseURL}/smark/smark-user-story/save-user-story`,
    updateStoryPriority: `${baseURL}/smark/smark-user-story/update-user-story-priority`,
    userStoryById: `${baseURL}/smark/smark-user-story/userstorydetailshb/getdetailsofauserstory/applicationid/252/siteid/171/userstoryid/{0}?swcmTicket=ffb09f96-737b-4c2a-bd5e-2f3b40a585a5`,
    addCommentToStory: `${baseURL}/smark/smark-user-story/save-comments`,
    deleteStory: `${baseURL}/smark/smark-user-story/delete-user-story`,
    storyComments: `${baseURL}/smark/smark-user-story/userstorydetailshb/getdetailsofauserstory/applicationid/${apiCommonParams.applicationid}/siteid/${apiCommonParams.siteid}/userstoryid/{0}?swcmTicket=${apiCommonParams.swcmTicket}`,

    getTaskById: `${baseURL}/smark/taskcommentshb/getallcommentsofatask/applicationid/${apiCommonParams.applicationid}/siteid/${apiCommonParams.siteid}/taskid/{0}/?swcmTicket=${apiCommonParams.swcmTicket}`,
    addTask: `${baseURL}/smark/save-task`,
    updateTaskStatus: `${baseURL}/smark/update-task-status`,
    deleteTask: `${baseURL}/smark/delete-task`,
    addCommentToTask: `${baseURL}/smark/save-comments`,
    removeTaskAssignee: `${baseURL}/smark/delete-assignee`,
    loginPath: `${adminBaseURL}/user/login`,
    isAdminPath: `${adminBaseURL}/user/isadmin/applicationid/252/userid/{0}?swcmTicket=ffb09f96-737b-4c2a-bd5e-2f3b40a585a5`,
    signUpPath: `${adminBaseURL}/user/user-register`,
    forgotPswd:`${adminBaseURL}/user/reset-password`,
    editProject:`${baseURL}/smark/validate-projectname`,
    editRelease:`${baseURL}/smark/validate-releasename`,
    userTO: `${baseURL}/user/userto/applicationid/334/username/{0}/type/username/?swcmTicket=ffb09f96-737b-4c2a-bd5e-2f3b40a585a5`
}

export default apiUrls;